import { setValue } from '@modular-forms/solid'
import { Component, For, createSignal } from 'solid-js'
import { MultiSelectFieldProps, SelectEntry } from '~/components/fields/multi-select/multi-select.interfaces'
import styles from '~/components/fields/multi-select/multi-select.module.scss'
import RowButton from '~/components/row-button/row-button'
import DeleteIcon from '~/assets/icons/delete.svg'
import UserIcon from '~/assets/icons/user.svg'
import { translations } from '~/translations'

const MultiSelectField: Component<MultiSelectFieldProps> = ({ name, label, error, value, options, form }) => {
  const [hasFocus, setHasFocus] = createSignal(false)
  const onFocus = () => {
    setHasFocus(true)
  }
  const onBlur = () => {
    setTimeout(() => {
      setHasFocus(false)
    }, 300)
  }

  const classList = () => ({
    [`${styles.container}`]: true,
    [`${styles.hasError}`]: Boolean(error)
  })
  const dropdownClassList = () => ({
    [`${styles.dropdown}`]: true,
    [`${styles.visible}`]: hasFocus(),
  })

  const availableOptions = () => {
    return options.filter(entry => !value?.includes(entry.value))
  }

  const selectedOptions = () => {
    const currentValues = value
    const result: SelectEntry[] = []
    currentValues?.forEach(value => {
      const label = options.find(option => option.value === value)?.label as string
      result.push({
        label,
        value
      })
    })
    return result
  }

  const onOptionAdd = (newValue: string) => () => {
    const newValues = value?.concat(newValue)
    setValue(form, name, newValues)
  }

  const onOptionDelete = (deletedValue: string) => () => {
    const newValues = value?.filter(entry => entry !== deletedValue)
    setValue(form, name, newValues)
  }

  return (
    <div classList={classList()}>
      <label
        for={name}
        class={styles.label}
      >
        {label}
      </label>

      <div class={styles.selectedValues}>
        <For each={selectedOptions()}>
          {(item) => (
            <div class={styles.entry}>
              <UserIcon class={styles.icon} />
              <div class={styles.label}>
                {item.label}
              </div>
              <RowButton
                icon={DeleteIcon}
                label={translations().general.actions.delete}
                onClick={onOptionDelete(item.value)}
              />
            </div>
          )}
        </For>
      </div>

      <div class={styles.autocomplete}>
        <input
          type='text'
          class={styles.textInput}
          placeholder={translations().general.fields.findInContacts}
          onFocus={onFocus}
          onBlur={onBlur}
        />
        <div classList={dropdownClassList()}>
          <For
            each={availableOptions()}
            fallback={(
              <div class={styles.emptyEntry}>
                {translations().general.search.noResult}
              </div>
            )}
          >
            {(item) => (
              <div
                class={styles.entry}
                onClick={onOptionAdd(item.value)}
              >
                {item.label}
              </div>
            )}
          </For>
        </div>
      </div>
    </div>
  )
}

export default MultiSelectField
